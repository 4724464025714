{
  "name": "landing-app",
  "version": "4.4.1-93ebae02.0",
  "scripts": {
    "install": "cp ./package.json ./src/assets",
    "ng": "ng",
    "start": "ng serve --ssl --host localhost.sparbanken.me --port 4201",
    "build": "./scripts/version.sh && ng build",
    "test": "ng test --watch=false --code-coverage",
    "watch": "ng test",
    "lint": "ng lint",
    "style-lint": "stylelint  \"src/**/*.scss\" --fix",
    "coverage": "browser-sync start --server ./coverage/landing-app --watch ''*.html"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.3",
    "@angular/cdk": "^18.0.3",
    "@angular/common": "^18.0.3",
    "@angular/compiler": "^18.0.3",
    "@angular/core": "^18.0.3",
    "@angular/forms": "^18.0.3",
    "@angular/material": "^18.0.3",
    "@angular/platform-browser": "^18.0.3",
    "@angular/platform-browser-dynamic": "^18.0.3",
    "@angular/router": "^18.0.3",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "zone.js": "~0.14.2"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.4",
    "@angular-eslint/builder": "18.0.1",
    "@angular-eslint/eslint-plugin": "18.0.1",
    "@angular-eslint/schematics": "18.0.1",
    "@angular-eslint/template-parser": "18.0.1",
    "@angular/cli": "^18.0.4",
    "@angular/compiler-cli": "^18.0.3",
    "@angular/material-luxon-adapter": "^18.0.3",
    "@sparbanken-syd/personnummer": "^2.0.0",
    "@sparbanken-syd/sparbanken-syd-bankid": "^18.1.1",
    "@sparbanken-syd/sparbanken-syd-qr": "^18.1.0",
    "@sparbanken-syd/sparbanken-syd-theme": "^18.0.0",
    "@sparbanken-syd/user-documents-backend": "^0.0.1",
    "@types/jasmine": "~5.1.2",
    "@types/luxon": "^3.3.4",
    "@types/node": "^20.9.0",
    "@types/sparbanken-syd-auth-backend": "^2.0.1",
    "@typescript-eslint/eslint-plugin": "^7.2.0",
    "@typescript-eslint/parser": "^7.2.0",
    "browser-sync": "^3.0.2",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.0",
    "eslint-plugin-jsdoc": "^46.9.0",
    "eslint-plugin-prefer-arrow": "^1.2.3",
    "jasmine-core": "~5.1.1",
    "karma": "~6.4.2",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "luxon": "^3.4.3",
    "postcss": "^8.4.31",
    "postcss-scss": "^4.0.9",
    "stylelint": "^16.2.0",
    "stylelint-config-standard-scss": "^13.0.0",
    "stylelint-scss": "^6.1.0",
    "typescript": "~5.4.5",
    "xlsx": "https://cdn.sheetjs.com/xlsx-0.20.0/xlsx-0.20.0.tgz"
  }
}
