import {Injectable} from '@angular/core'

import {Observable} from 'rxjs'
import {Site} from '../services/types'
import {SiteService} from '../services/site.service'

@Injectable({providedIn: 'root'})
export class SiteResolver  {
  constructor(private service: SiteService) {
  }

  resolve(): Observable<Site[]> {
    return this.service.getAllSites()
  }
}
