import {Injectable} from '@angular/core'

import {Observable} from 'rxjs'
import {UserService} from '../services/user.service'
import {User} from '../services/types'

@Injectable({providedIn: 'root'})
export class UserResolver  {
  constructor(private service: UserService) {
  }

  resolve(): Observable<User[]> {
    return this.service.getUsers()
  }
}
