import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {HOME_ROUTE_PATH, LOGIN_ROUTE_PATH, ADMIN_ROUTE_PATH, DEPLOY_ROUTE_PATH} from './application/data-types'
import {authGuard} from './application/auth.guard'
import {UserResolver} from './application/user.resolver'
import {RoleResolver} from './application/role.resolver'
import {SiteResolver} from './application/site.resolver'
import {DeployHomeComponent} from './deploy/deploy-home/deploy-home.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: LOGIN_ROUTE_PATH
  },
  {
    path: HOME_ROUTE_PATH,
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
    canActivate: [authGuard],
    resolve: {
      sites: SiteResolver,
      roles: RoleResolver,
    }
  },
  {
    path: LOGIN_ROUTE_PATH,
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
  },
  {
    path: DEPLOY_ROUTE_PATH,
    loadChildren: () => import('./deploy/deploy.module').then(m => m.DeployModule),
    canActivate: [authGuard],
  },
  {
    path: 'gitlab',
    component: DeployHomeComponent
  },
  {
    path: ADMIN_ROUTE_PATH,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [authGuard],
    resolve: {
      users: UserResolver,
      roles: RoleResolver,
      sites: SiteResolver
    }
  },
  {
    path: '**',
    redirectTo: LOGIN_ROUTE_PATH
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false, useHash: true,
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
